import { CheckCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormData } from '../../../../interfaces/IFormData';
import { IFormErrors } from '../../../../interfaces/IFormErrors';
import { ErrorMessage, FormButton, FormContainer, FormInput } from './styles';

const Form: React.FC = () => {
  const [formData, setFormData] = useState<IFormData>({
    fullName: '',
    company: '',
    email: '',
    cnpj: '',
    phone: '',
    position: '',
  });

  const [formErrors, setFormErrors] = useState<IFormErrors>({
    fullName: '',
    company: '',
    email: '',
    phone: '',
    position: '',
    general: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const validateField = useCallback(
    (name: string, value: string): string => {
      if (['fullName', 'company', 'position'].includes(name) && value.trim() === '') {
        return 'Este campo é obrigatório.';
      }
      if ((name === 'email' || name === 'phone') && !formData.email.trim() && !formData.phone.trim()) {
        return 'É necessário fornecer pelo menos um contato.';
      }

      if (name === 'email' && value.trim() !== '') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          return 'Formato de e-mail inválido.';
        }
      }
      return '';
    },
    [formData.email, formData.phone]
  );

  const maskPhone = (value: string): string => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
  };

  const maskCNPJ = (value: string): string => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .substring(0, 18);
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      let { value } = e.target;

      if (name === 'phone') {
        value = maskPhone(value);
      }
      if (name === 'cnpj') {
        value = maskCNPJ(value);
      }

      const fieldError = validateField(name, value);

      setFormData({
        ...formData,
        [name]: value,
      });

      setFormErrors({
        ...formErrors,
        [name]: fieldError,
        general: '',
      });
    },
    [formData, formErrors, validateField]
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const newErrors: IFormErrors = {
        fullName: validateField('fullName', formData.fullName),
        company: validateField('company', formData.company),
        email: validateField('email', formData.email),
        phone: validateField('phone', formData.phone),
        position: validateField('position', formData.position),
        general: '',
      };

      const hasError = Object.values(newErrors).some((error) => error !== '');

      if (hasError) {
        setFormErrors({
          ...newErrors,
          general: 'Um ou mais campos possuem um erro. Verifique e tente novamente.',
        });
      } else {
        const payload = {
          nome: formData.fullName,
          cargo: formData.position,
          email: formData.email,
          telefone: formData.phone,
          empresa: formData.company,
          cnpj: formData.cnpj,
        };
        try {
          await fetch('https://api-ssmi.ms.senai.br/email-app/mail/send/portal/form', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
          setIsModalOpen(true);
        } catch (error) {
          console.error('Erro ao enviar formulário:', error);
        }

        setFormData({
          fullName: '',
          company: '',
          email: '',
          cnpj: '',
          phone: '',
          position: '',
        });
        setFormErrors({
          fullName: '',
          company: '',
          email: '',
          phone: '',
          position: '',
          general: '',
        });
        console.debug('Form Data Submitted:', formData);
      }
    },
    [formData, validateField]
  );

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormInput
        type="text"
        name="fullName"
        placeholder={t('placeholders.name')}
        value={formData.fullName}
        onChange={handleChange}
      />
      {formErrors.fullName && <ErrorMessage>{formErrors.fullName}</ErrorMessage>}

      <FormInput
        type="text"
        name="position"
        placeholder={t('placeholders.position')}
        value={formData.position}
        onChange={handleChange}
      />
      {formErrors.position && <ErrorMessage>{formErrors.position}</ErrorMessage>}

      <FormInput
        type="email"
        name="email"
        placeholder={t('placeholders.email')}
        value={formData.email}
        onChange={handleChange}
      />
      {formErrors.email && <ErrorMessage>{formErrors.email}</ErrorMessage>}

      <FormInput
        type="tel"
        name="phone"
        placeholder={t('placeholders.phone')}
        value={formData.phone}
        onChange={handleChange}
      />
      {formErrors.phone && <ErrorMessage>{formErrors.phone}</ErrorMessage>}

      <FormInput
        type="text"
        name="company"
        placeholder={t('placeholders.company')}
        value={formData.company}
        onChange={handleChange}
      />
      {formErrors.company && <ErrorMessage>{formErrors.company}</ErrorMessage>}

      <FormInput
        type="text"
        name="cnpj"
        placeholder={t('placeholders.registry')}
        value={formData.cnpj}
        onChange={handleChange}
      />

      <FormButton type="submit">{t('buttons.send')}</FormButton>
      {formErrors.general && <ErrorMessage>{formErrors.general}</ErrorMessage>}

      <Modal
        title="Sucesso"
        open={isModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <FormButton style={{ maxWidth: '100%' }} type="button" key="ok" onClick={handleModalClose}>
            OK
          </FormButton>,
        ]}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
          <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '24px' }} />
          <p>Formulário enviado com sucesso!</p>
        </div>
      </Modal>
    </FormContainer>
  );
};

export default Form;
